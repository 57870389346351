import { createRouter, createWebHistory } from 'vue-router';
import LoginComponent from '../components/LoginComponent.vue';
//import Main from '../components/Main.vue';

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: LoginComponent,
  },
 // {
   // path: '/main',
    //name: 'Main',
    //component: Main,
    //meta: { requiresAuth: true }, // Require authentication for this route
  //},
  {
    path: '/',
    redirect: '/login', // Redirect to login by default
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Navigation guard to check authentication
router.beforeEach((to, from, next) => {
  const isAuthenticated = !!localStorage.getItem('username'); // Simple auth check
  if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) {
    next('/login'); // Redirect to login if not authenticated
  } else {
    next();
  }
});

export default router;
